@import url("./normalize.css");
@import url("https://fonts.googleapis.com/css?family=Quicksand:500&display=swap");

body {
	font-family: "Quicksand", sans-serif;
	color: #a1b2b5;
}

#gradient {
	--color-stop-1: #a770ef;
	--color-stop-2: #cf8bf3;
	--color-stop-3: #fdb99b;
}
